import React, { Fragment, useRef, useEffect } from "react"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import TextArea from "routes/Admin/Components/TextArea"
import shippinginfoenhancer from "./shippinginfoenhancer"
import { useSelector } from "react-redux"
import { Form, Formik } from "formik"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"

function ShippingInformation(props) {
  let {
    formik,
    containerTypeOpts,
    frequencyOpts,
    dotOptionNames,
    backAction,
    cancelAction,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    genNameforView,
  } = props

  const { type } = useParams()
  const ref = useRef()
  const currentProfile = useSelector(
    (state) => state.profileDetails?.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  return (
    <Fragment>
      {/* Waste Characteristics */}
      <Formik
        enableReinitialize={true}
        initialValues={formik?.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => (
          <Form>
            <div
              className="ml-4 shipping-info"
              style={{
                pointerEvents: type === "viewprofile" ? "none" : "auto",
              }}
            >
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                <div
                  className="m-0 ml-2 myprofile"
                  style={{
                    color: "#337AB7",
                    fontWeight: "bold",
                  }}
                >
                  <FormattedMessage id="profile.shippinginformation" />
                </div>
                <div className="form-input-block col-sm-4">
                  <span className="label">
                    <FormattedMessage id="profile.generator" />
                  </span>
                  <Input
                    value={
                      getDefaultGeneratorName(currentProfile?.generatorId) ||
                      genNameforView
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-2">
                <div className="m-0 ml-2 myprofile">
                  <FormattedMessage id="profile.methodofshipment" />
                  <span style={{ color: "red" }}>{"* "}</span>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative mt-1"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="d-flex">
                  <label className="check-container">
                    <input
                      type="checkbox"
                      name={"bulkLiquidFlag"}
                      checked={values?.bulkLiquidFlag}
                      onChange={(e) => {
                        setFieldValue("bulkLiquidFlag", e.target.checked)
                      }}
                    />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="pl-4 pr-0 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id={"profile.bulkliquid"} />
                  </span>
                </div>
                <div className="d-flex ml-4">
                  <label className="check-container">
                    <input
                      type="checkbox"
                      name={"bulkSolidFlag"}
                      checked={values?.bulkSolidFlag}
                      onChange={(e) => {
                        setFieldValue("bulkSolidFlag", e.target.checked)
                      }}
                    />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="pl-4 pr-0 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id={"profile.bulksolid"} />
                  </span>
                </div>
                <div className="d-flex ml-4">
                  <label className="check-container">
                    <input
                      type="checkbox"
                      name={"containerTypesizeFlag"}
                      checked={values?.containerTypesizeFlag}
                      onChange={(e) => {
                        const isChecked = e.target.checked
                        setFieldValue("containerTypesizeFlag", isChecked)
                        if (!isChecked) {
                          setFieldValue("containerTypesize", "")
                        }
                      }}
                    />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="pl-4 pr-0 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id={"profile.containersize"} />
                  </span>
                </div>
                <div
                  className="form-input-block ml-2 col-sm-2"
                  style={{
                    marginBottom:
                      values.containerTypesizeFlag === true &&
                      values?.containerTypesize?.trim() === "" &&
                      Object.keys(touched).length !== 0
                        ? "-20px"
                        : "",
                  }}
                >
                  <Input
                    placeholder="Container Size"
                    name="containerTypesize"
                    value={values?.containerTypesize}
                    disabled={!values?.containerTypesizeFlag}
                    onFieldChange={(e) => {
                      setFieldValue("containerTypesize", e.target.value)
                    }}
                  />
                  {values.containerTypesizeFlag === true &&
                  values?.containerTypesize?.trim() === "" &&
                  errors?.containerTypesize &&
                  Object.keys(touched).length !== 0 ? (
                    <div style={{ color: "red", fontSize: 10 }}>
                      <div style={{ paddingTop: "5px" }}>
                        Container size is required
                      </div>
                    </div>
                  ) : null}
                </div>
                <div className="dsp-flx ml-4">
                  <span className="label pl-0" style={{ fontSize: 12 }}>
                    <FormattedMessage id="profile.containertype" />
                    <span style={{ color: "red" }}>{" *"}</span>
                  </span>
                  <div
                    className="form-input-block ml-4 mb-dropdown mni-style"
                    style={{ minWidth: 160 }}
                  >
                    <Select
                      name="containerTypeId"
                      labelkey={"l"}
                      label=""
                      className={`sort-dropdown ${
                        values?.containerTypeId?.v == ""
                          ? "mni-selected-dropdown"
                          : ""
                      }`}
                      options={containerTypeOpts.options}
                      selected={values?.containerTypeId}
                      disabled={type === "viewprofile"}
                      onSelect={(e) => {
                        setFieldValue("containerTypeId", e)
                      }}
                    />
                  </div>
                </div>
                {/* <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
            {errors.containerTypesize && touched.containerTypesize ? (
              <div>Container size is required</div>
                ) : null}
          </div>
          <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
            {errors.containerTypeId && touched.containerTypeId ? (
              <div>Container type is required</div>
                ) : null}
          </div> */}
              </div>
              <div
                style={{
                  color: "red",
                  fontSize: 10,
                  paddingTop: 0,
                  paddingRight: 143,
                  textAlign: "right",
                }}
              >
                {errors?.containerTypeId?.id && touched?.containerTypeId?.id ? (
                  <div>{errors?.containerTypeId?.id}</div>
                ) : null}
              </div>
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 ml-2 mt-4 text-left py-3">
                <div className="col-sm-4" style={{ top: "-2px" }}>
                  <div className="form-input-block mb-dropdown mni-style">
                    <span className="label">
                      <FormattedMessage id="profile.frequency" />
                      <span style={{ color: "red" }}>{"* "}</span>
                    </span>
                    <Select
                      name="frequencyId"
                      label=""
                      labelkey={"l"}
                      className={`sort-dropdown ${
                        values?.frequencyId?.v == ""
                          ? "mni-selected-dropdown"
                          : ""
                      }`}
                      options={frequencyOpts.options}
                      selected={values?.frequencyId}
                      disabled={type === "viewprofile"}
                      onSelect={(e) => {
                        setFieldValue("frequencyId", e)
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-input-block">
                    <span className="label">
                      <FormattedMessage id="profile.qtydescription" />
                      <span style={{ color: "red" }}>{" *"}</span>
                    </span>
                    <Input
                      placeholder="Quantity Description"
                      name="quantity"
                      value={values?.quantity}
                      onFieldChange={(e) => {
                        setFieldValue("quantity", e.target.value)
                      }}
                    />
                  </div>
                </div>
                <div className="col-sm-4"></div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-0"
                style={{ marginTop: "-10px" }}
              >
                <div className="col-sm-4">
                  {errors?.frequencyId?.id && touched?.frequencyId?.id ? (
                    <div
                      style={{ color: "red", fontSize: 10, marginLeft: "5px" }}
                    >
                      <div>{errors?.frequencyId?.id}</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-4">
                  {errors?.quantity && touched?.quantity ? (
                    <div
                      style={{ color: "red", fontSize: 10, marginLeft: "25px" }}
                    >
                      <div>Quantity description is required</div>
                    </div>
                  ) : null}
                </div>
                <div className="col-sm-4"></div>
              </div>
              <span className="ml-2">
                <hr />
              </span>
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-2">
                <div className="m-0 ml-2 myprofile">
                  <FormattedMessage id="profile.addeditdotnames" />
                  <span style={{ color: "red" }}>{"* "}</span>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative mt-1"
                style={{ justifyContent: "flex-start" }}
              >
                <div className="d-flex">
                  <label className="check-container">
                    <input
                      type="checkbox"
                      name={"searchAnywhereFlag"}
                      checked={values?.searchAnywhereFlag}
                      onChange={(e) => {
                        setFieldValue("searchAnywhereFlag", e.target.checked)
                      }}
                    />
                    <span className={"checkmark"} />
                  </label>
                  <span
                    className="pl-4 pr-0 text-description"
                    style={{ marginLeft: "16PX" }}
                  >
                    <FormattedMessage id={"profile.searchanywhere"} />
                  </span>
                </div>
              </div>
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 ml-2 text-left py-2 position-relative">
                <div className="col-sm-8">
                  <div className="form-input-block mb-dropdown dot-name mni-style">
                    <span className="label">
                      <FormattedMessage id="profile.dotname" />
                      <span style={{ color: "red" }}>{"* "}</span>
                    </span>
                    <Select
                      name="dotId"
                      label=""
                      labelkey={"l"}
                      className={`sort-dropdown ${
                        values?.dotId?.v == 0 ? "mni-selected-dropdown" : ""
                      }`}
                      options={dotOptionNames}
                      selected={values?.dotId}
                      hasSearch={true}
                      disabled={type === "viewprofile"}
                      onSelect={(e) => {
                        setFieldValue("dotId", e)
                      }}
                    />
                  </div>
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                      paddingTop: 10,
                      position: "absolute",
                      top: 32,
                      left: 19,
                    }}
                  >
                    {errors?.dotId && touched?.dotId ? (
                      <div>{errors?.dotId?.v}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-input-block">
                    <span className="label">
                      <FormattedMessage id="profile.hazardousconstituents" />
                    </span>
                    <Input
                      name="hazardousConstituents"
                      value={values?.hazardousConstituents}
                      onFieldChange={(e) => {
                        setFieldValue("hazardousConstituents", e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left ml-1 py-2 position-relative"
                style={{ marginTop: 10 }}
              >
                <div className="col-sm-8 pl-0">
                  <div className="d-flex">
                    <div className="d-flex">
                      <label className="check-container">
                        <input
                          type="checkbox"
                          name={"rq"}
                          checked={values?.rq}
                          onChange={(e) => {
                            setFieldValue("rq", e.target.checked)
                          }}
                        />
                        <span className={"checkmark"} />
                      </label>
                      <span
                        className="pl-4 pr-0 text-description"
                        style={{ marginLeft: "16PX" }}
                      >
                        <FormattedMessage id={"profile.rq"} />
                      </span>
                    </div>
                    <div className="d-flex ml-4">
                      <label className="check-container">
                        <input
                          type="checkbox"
                          name={"limitedQty"}
                          checked={values?.limitedQty}
                          onChange={(e) => {
                            setFieldValue("limitedQty", e.target.checked)
                          }}
                        />
                        <span className={"checkmark"} />
                      </label>
                      <span
                        className="pl-4 pr-0 text-description"
                        style={{ marginLeft: "16PX" }}
                      >
                        <FormattedMessage id={"profile.ltdqty"} />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-input-block">
                    <span className="label">
                      <FormattedMessage id="profile.rqdescription" />
                    </span>
                    <Input
                      name="rqDescription"
                      value={values?.rqDescription}
                      onFieldChange={(e) => {
                        setFieldValue("rqDescription", e.target.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 position-relative"
                style={{ marginLeft: "-20px" }}
              >
                <div className="col-sm-8 dsp-flx">
                  <div className="form-input-block col-sm-6">
                    <span className="label" style={{ zIndex: 2 }}>
                      <FormattedMessage id="profile.forshipmentsbyvessel" />
                    </span>
                    <Input
                      name="shipmentsOverSeaFlashpoint"
                      value={values?.shipmentsOverSeaFlashpoint}
                      disabled={true}
                      // onFieldChange={(e) => {
                      //   const { value } = e.target
                      //   if (/^\d*$/.test(value)) {
                      //     setFieldValue("shipmentsOverSeaFlashpoint", value)
                      //   }
                      // }}
                    />
                    {/* <span
                      style={{ color: "red", fontSize: 10, marginLeft: 10 }}
                    >
                      {errors?.shipmentsOverSeaFlashpoint &&
                      touched?.shipmentsOverSeaFlashpoint ? (
                        <>{errors?.shipmentsOverSeaFlashpoint}</>
                      ) : null}
                    </span> */}
                  </div>
                  <div className="d-flex">
                    <label className="check-container">
                      <input
                        type="checkbox"
                        name={"marinePollutants"}
                        checked={values?.marinePollutants}
                        onChange={(e) => {
                          setFieldValue("marinePollutants", e.target.checked)
                        }}
                      />
                      <span className={"checkmark"} />
                    </label>
                    <span
                      className="pl-4 pr-0 text-description"
                      style={{ marginLeft: "16PX" }}
                    >
                      <FormattedMessage id={"profile.marinepollutants"} />
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="dsp-flx col-sm-12 col-md-12 col-lg-12 mt-4 text-left py-2 position-relative"
                style={{ marginLeft: "-20px" }}
              >
                <div className="col-sm-8 dsp-flx">
                  <div className="form-input-block col-sm-12">
                    <span className="label">
                      <FormattedMessage id="profile.specialpermits" />
                    </span>
                    <TextArea
                      name="specialPermitsCaLetter"
                      value={values?.specialPermitsCaLetter}
                      rows={3}
                      onFieldChange={(e) => {
                        setFieldValue("specialPermitsCaLetter", e.target.value)
                      }}
                      noMaxLength={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Back, Save, Cancel Actions */}
            {type !== "viewprofile" && (
              <div className="row m-0 new-row-block mt-4">
                <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-4 ml-4">
                  <div className="row m-0 float-left">
                    <button
                      type="button"
                      className={`cancel-button`}
                      onClick={() => backAction()}
                    >
                      Back
                    </button>
                  </div>
                  <div className="row m-0 float-right m-r-2r">
                    <button
                      type="submit"
                      ref={ref}
                      className={`green-button`}
                      // onClick={() => submit()}
                    >
                      Save & Continue
                    </button>
                  </div>
                  <div className="row m-0 float-right m-r-2r">
                    <button
                      type="button"
                      className="cancel-button"
                      onClick={() => cancelAction()}
                    >
                      Cancel
                    </button>
                  </div>
                  <span className="float-right text-red m-r-2r mt-2">
                    {`*Required Fields`}
                  </span>
                </div>
                <AlertModal
                  showError={showError}
                  setShowError={setShowError}
                  seekingStep={seekingStep}
                  submitAction={() =>
                    handleAlerts(values, errors, isValid, seekingStep)
                  }
                  showAlert={showAlert}
                  setShowAlert={setShowAlert}
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}
export default shippinginfoenhancer(ShippingInformation)
