import React, { Fragment, useState, useRef, useEffect } from "react"
// import enhancer from "./enhancer"
// import api from "api"
import "./style.scss"
import { FormattedMessage } from "react-intl"
import Select from "shared/Select"
import Input from "routes/Admin/Components/Input"
import TextArea from "routes/Admin/Components/TextArea"
import statereginfoenhancer from "./statereginfoenhancer"
import Modal from "shared/Modal"
// import { SELECTED_LDR_UHCS, AVAILABLE_LDR_UHCS } from "store/reducer/actionType"
import { useSelector } from "react-redux"
import { Form, Formik } from "formik"
import EditRegInfoCodes from "./EditRegInfoCodes"
import { useParams } from "react-router-dom/cjs/react-router-dom"
import AlertModal from "./AlertModal"
// import { useDispatch } from "react-redux"

function StateRegulatoryInfo(props) {
  let {
    formik,
    wasteWaterOpts,
    ldrClassOpts,
    epaWasteCode,
    setEPAWasteCode,
    epaStateCode,
    setEPAStateCode,
    epaUhc,
    setEPAUHC,
    ldrRcraCode,
    setLdrRcraCode,
    ldrUhcs,
    setLdrUhcs,
    EpaStateCodeOpts,
    EpaUhcOpts,
    EpaWasteCodeOpts,
    ldrUhcOpts,
    ldrRcraOpts,
    selectedEpaWasteOpts,
    selectedEpaStateCodeOpts,
    selectedEpaUhcOpts,
    selectedLdrRcraOpts,
    selectedLdrUhcOpts,
    handleGetProfileData,
    // hasLDRError,
    setHasLDRError,
    // noUhcFlag,
    // setNoUhcFlag,
    backAction,
    cancelAction,
    naicsCode,
    setNaicsCode,
    showAlert,
    setShowAlert,
    handleAlerts,
    seekingStep,
    showError,
    setShowError,
    genNameforView,
    isTxCode,
    txState,
    setTxState,
    // texasCodeOpts,
  } = props

  const { type } = useParams()
  const ref = useRef()
  const [wasteCodeEdit, setWasteCodeEdit] = useState(false)
  const [UHCEdit, setUHCEdit] = useState(false)
  const [stateCodeEdit, setStateCodeEdit] = useState(false)
  const [LDRRCRAEdit, setLDRRCRAEdit] = useState(false)
  const [LDRUHCEdit, setLDRUHCEdit] = useState(false)

  // const [txStateSuggestions, setTxStateSuggestions] = useState("")
  const minCharsForSuggestion = 3
  // const dispatch = useDispatch()

  //eslint-disable-next-line
  const [naicsCombCode, setNaicsCombCode] = useState("")

  //eslint-disable-next-line
  const handleNaicsCode = (newCombCode) => {
    setNaicsCombCode(newCombCode)
  }

  useEffect(() => {
    if (showAlert) {
      ref.current.click()
    }
  }, [showAlert])

  useEffect(() => {
    let LdrRcraError = false
    let LdrUhcError = false
    if (
      epaWasteCode?.length > 0 &&
      (ldrRcraCode === null ||
        ldrRcraCode === undefined ||
        ldrRcraCode?.length === 0)
    )
      LdrRcraError = true
    if (
      epaUhc?.length > 0 &&
      (ldrUhcs === null || ldrUhcs === undefined || ldrUhcs?.length === 0)
    )
      LdrUhcError = true
    setHasLDRError(LdrRcraError || LdrUhcError)
  }, [epaWasteCode, epaUhc, ldrRcraCode, ldrUhcs])

  // useEffect(() => {
  //   if (txState?.length > 2) {
  //     const _suggestions = texasCodeOpts?.filter((val) =>
  //       val?.epaCode?.toLowerCase()?.includes(txState?.toLowerCase())
  //     )
  //     setTxStateSuggestions(_suggestions)
  //   } else {
  //     setTxStateSuggestions([])
  //   }
  // }, [txState])

  const handleSelectEditCode = (code, type) => {
    switch (type) {
      case "EPAWaste":
        setEPAWasteCode(code)
        break
      case "EPAUHC":
        setEPAUHC(code)
        break
      case "stateCode":
        setEPAStateCode(code)
        break
      case "LdrRCRA":
        setLdrRcraCode(code)
        break
      case "LdrUHC":
        setLdrUhcs(code)
        // setCheckLdruhcs(code)
        break
      default:
        break
    }
  }

  const currentProfile = useSelector(
    (state) => state.profileDetails.currentProfile
  )
  const generatorOptions = useSelector(
    (state) => state.generatorDetails.generatorList
  )

  const getDefaultGeneratorName = (id) => {
    return (
      generatorOptions?.filter((val) => val.generatorId === id)?.[0]
        ?.generatorName || ""
    )
  }

  // const handleFederalUniversalWasteChange = (event) => {
  //   setFederalUniversalWaste(event.target.value);
  // };

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={formik.values}
        {...formik}
      >
        {({ values, errors, touched, setFieldValue, isValid }) => (
          <Form>
            <div
              className="ml-4 usepa-statereg"
              style={{
                pointerEvents: type === "viewprofile" ? "none" : "auto",
              }}
            >
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus addi-info text-left py-4">
                <div className="m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.stateregulatoryinfotitle" />
                </div>
                <div className="form-input-block col-sm-4">
                  <span className="label">
                    <FormattedMessage id="profile.generator" />
                  </span>
                  <Input
                    value={
                      getDefaultGeneratorName(currentProfile?.generatorId) ||
                      genNameforView
                    }
                    disabled={true}
                  />
                </div>
              </div>
              <div>
                <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="col-sm-5 col-md-5 col-lg-5 qstn-container">
                    <div className="text-heading">
                      <FormattedMessage
                        id={"profile.regulatoryinfo.federalwaste"}
                      />
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div className="px-2 dsp-flx py-1">
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="federalUniversalWaste"
                          value={values?.federalUniversalWaste}
                          checked={
                            values?.federalUniversalWaste === true
                              ? true
                              : false
                          }
                          onChange={() => {
                            setFieldValue("federalUniversalWaste", true)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opyes"} />
                        </div>
                      </div>
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="federalUniversalWaste"
                          value={values?.federalUniversalWaste}
                          checked={
                            values?.federalUniversalWaste === false
                              ? true
                              : false
                          }
                          onChange={() => {
                            setFieldValue("federalUniversalWaste", false)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opno"} />
                        </div>
                      </div>
                    </div>
                    <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                      {errors.federalUniversalWaste &&
                      touched.federalUniversalWaste ? (
                        <div>Federal Universal waste is required</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 qstn-container">
                    <div className="col-sm-8 text-heading">
                      <FormattedMessage
                        id={"profile.regulatoryinfo.generatorstatewaste"}
                      />
                      <span style={{ color: "red" }}>{"*"}</span>
                    </div>
                    <div className="px-1 dsp-flx py-1">
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="generatorStateUniversalWaste"
                          value={values?.generatorStateUniversalWaste}
                          checked={
                            values?.generatorStateUniversalWaste === true
                              ? true
                              : false
                          }
                          onChange={() => {
                            setFieldValue("generatorStateUniversalWaste", true)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opyes"} />
                        </div>
                      </div>
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="generatorStateUniversalWaste"
                          value={values?.generatorStateUniversalWaste}
                          checked={
                            values?.generatorStateUniversalWaste === false
                              ? true
                              : false
                          }
                          onChange={() => {
                            setFieldValue("generatorStateUniversalWaste", false)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opno"} />
                        </div>
                      </div>
                    </div>
                    <div style={{ color: "red", fontSize: 10, paddingTop: 10 }}>
                      {errors.generatorStateUniversalWaste &&
                      touched.generatorStateUniversalWaste ? (
                        <div>Generator State Universal waste is required</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 generatorstatus text-left py-4">
                  <div
                    className="d-flex form-input-block px-4"
                    style={{ alignContent: "baseline" }}
                  >
                    <div className="my-profile fs-12">
                      <FormattedMessage id="profile.regulatoryinfo.rcra" />
                      {/* <span style={{ color: "red" }}>{" *"}</span> */}
                    </div>
                    <div className="d-flex py-1">
                      <label className="check-container">
                        <input
                          name="rcraNonHazExempt"
                          type="checkbox"
                          checked={values?.rcraNonHazExempt}
                          onChange={(e) => {
                            setFieldValue("rcraNonHazExempt", e.target.checked)
                          }}
                        />
                        <span className={"checkmark"} />
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-4 ml-2">
                    <div
                      className="form-input-block"
                      style={{ marginLeft: 210, width: "100%" }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.regulatory.citeexemption" />
                      </span>
                      <Input
                        placeholder={"Enter State Waste Code"}
                        name={"epaStateExemption"}
                        value={values?.epaStateExemption}
                        onFieldChange={(e) => {
                          setFieldValue("epaStateExemption", e.target.value)
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: "red",
                        fontSize: 10,
                        position: "relative",
                        left: 218,
                        top: 2,
                        right: -10,
                      }}
                    >
                      {values?.rcraNonHazExempt &&
                      values?.epaStateExemption?.length === 0 &&
                      errors.epaStateExemption &&
                      touched.epaStateExemption ? (
                        <div>EPA State Exemption is required</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* <div
                  className="text-left"
                  style={{
                    color: "red",
                    fontSize: 10,
                    position: "relative",
                    top: -7,
                    left: 39,
                  }}
                >
                  {values?.rcraNonHazExempt === false &&
                  errors.rcraNonHazExempt &&
                  touched.rcraNonHazExempt ? (
                    <div>RCRA NonHaz Exempt is required</div>
                  ) : null}
                </div> */}
                <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="col-sm-8 ">
                    <div
                      className="form-input-block"
                      style={{ pointerEvents: "auto" }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.regulatoryinfo.epawastecode" />
                      </span>
                      <TextArea
                        name={"epawastecode"}
                        clsName={"epa-code-ta"}
                        noMaxLength={true}
                        disabled={true}
                        value={epaWasteCode}
                        placeholder={"Select Waste Code"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 pb-4" style={{ marginTop: 15 }}>
                    <button
                      type="button"
                      className={`edit-button`}
                      onClick={() => setWasteCodeEdit(true)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="col-sm-8 ">
                    <div
                      className="form-input-block"
                      style={{ pointerEvents: "auto" }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.regulatoryinfo.uhc" />
                      </span>
                      <TextArea
                        name={"epaUhc"}
                        clsName={"epa-code-ta"}
                        noMaxLength={true}
                        disabled={true}
                        value={epaUhc}
                        placeholder={"Enter UHC"}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 pb-4" style={{ marginTop: 15 }}>
                    <button
                      type="button"
                      className={`edit-button`}
                      onClick={() => {
                        setUHCEdit(true)
                      }}
                    >
                      Edit
                    </button>
                  </div>
                </div>
                <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="col-sm-8 ">
                    <div
                      className="form-input-block"
                      style={{ pointerEvents: "auto" }}
                    >
                      <span className="label">
                        <FormattedMessage id="profile.regulatoryinfo.statecode" />
                      </span>
                      <TextArea
                        name={"epaStateCode"}
                        // value={values?.epaStateCode}
                        value={epaStateCode}
                        noMaxLength={true}
                        clsName={"epa-code-ta"}
                        disabled={true}
                        placeholder={"Enter State Code"}
                        // onFieldChange={() => {
                        //   setFieldValue("epaStateCode", epaStateCode)
                        // }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 pb-4" style={{ marginTop: 15 }}>
                    {/* <div className="row m-0 float-right m-r-2r"> */}
                    <button
                      type="button"
                      className={`edit-button`}
                      onClick={() => {
                        setStateCodeEdit(true)
                      }}
                    >
                      Edit
                    </button>
                    {/* </div> */}
                  </div>
                </div>
                <div
                  className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative"
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <div className="col-sm-4 ">
                    <div className="form-input-block">
                      <span className="label">
                        <FormattedMessage id="profile.regulatoryinfo.statewastecode" />
                        {/* <span style={{ color: "red" }}>{" *"}</span> */}
                      </span>
                      {/* {isTxCode ? (
                        <>
                          {" "}
                          <Input
                            name="txInStateCode"
                            value={txState}
                            search={true}
                            keyName="epaCode"
                            suggestions={txStateSuggestions}
                            placeholder="Enter State Waste Code"
                            onFieldChange={(e) => {
                              setTxState(e.target.value)
                              if (!e.target.value) {
                                setFieldValue("txInStateCode", e.target.value)
                              }
                            }}
                            onSelect={(e) => {
                              setFieldValue("txInStateCode", e.epaCode)
                            }}
                          />{" "}
                          <div
                            style={{
                              color: "red",
                              fontSize: 10,
                              position: "relative",
                              paddingTop: 10,
                              left: 12,
                            }}
                          >
                            {errors?.txInStateCode && touched?.txInStateCode ? (
                              <div> {errors.txInStateCode}</div>
                            ) : null}
                          </div>
                          {
                            <div
                              style={{
                                display: "flex",
                                marginTop:
                                  errors.txInStateCode && touched.txInStateCode
                                    ? "-12px"
                                    : 0,
                                justifyContent: "flex-end",
                                color: "#000",
                                fontSize: 11,
                              }}
                            >
                              {txState && txState.length < minCharsForSuggestion
                                ? `Enter ${
                                    minCharsForSuggestion - txState.length
                                  } more character${
                                    txState.length < 2 ? "s" : ""
                                  }`
                                : txState === ""
                                ? "Enter 3 characters"
                                : ""}
                            </div>
                          }
                        </>
                      ) : (
                        <Input
                          placeholder={"Enter State Waste Code"}
                          name={"txInStateCode"}
                          value={values?.txInStateCode}
                          onFieldChange={(e) => {
                            setFieldValue("txInStateCode", e.target.value)
                          }}
                        />
                      )} */}{" "}
                      <Input
                        name="txInStateCode"
                        value={isTxCode ? txState : values?.txInStateCode}
                        // search={true}
                        // keyName="epaCode"
                        disabled={!isTxCode}
                        // suggestions={txStateSuggestions}
                        maxLen={8}
                        placeholder="Enter Texas State Waste Code"
                        onFieldChange={(e) => {
                          const { value } = e.target
                          if (value?.length <= 8) {
                            if (/^[a-zA-Z0-9]*$/.test(value)) {
                              setTxState(e.target.value)
                              setFieldValue("txInStateCode", e.target.value)
                            }
                          }
                        }}
                      />{" "}
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                          position: "relative",
                          paddingTop: 10,
                          left: 12,
                        }}
                      >
                        {errors?.txInStateCode && touched?.txInStateCode ? (
                          <div> {errors.txInStateCode}</div>
                        ) : null}
                      </div>
                      {isTxCode && (
                        <div
                          style={{
                            display: "flex",
                            marginTop:
                              errors.txInStateCode && touched.txInStateCode
                                ? "-12px"
                                : 0,
                            justifyContent: "flex-end",
                            color: "#000",
                            fontSize: 11,
                          }}
                        >
                          {txState && txState.length < minCharsForSuggestion
                            ? `Enter ${
                                minCharsForSuggestion - txState.length
                              } more character${txState.length < 2 ? "s" : ""}`
                            : txState === ""
                            ? "Enter 3 characters"
                            : ""}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus addi-info text-left py-4">
                <div className="m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.landinfotitle" />
                </div>
              </div>
              <div>
                <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="qstn-container">
                    <div className="text-heading">
                      <FormattedMessage
                        id={"profile.regulatoryinfo.ldrwaste"}
                      />
                    </div>
                    <div className="px-2 dsp-flx py-1">
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="ldrFlag"
                          value={values?.ldrFlag}
                          checked={values?.ldrFlag === true ? true : false}
                          onChange={() => {
                            setFieldValue("ldrFlag", true)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opyes"} />
                        </div>
                      </div>
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="ldrFlag"
                          value={values?.ldrFlag}
                          checked={values?.ldrFlag === false ? true : false}
                          onChange={() => {
                            setFieldValue("ldrFlag", false)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opno"} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {values?.ldrFlag && ( */}
                <div>
                  <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                    <div className="col-sm-8 ">
                      <div
                        className="form-input-block"
                        style={{ pointerEvents: "auto" }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.regulatoryinfo.ldrrcracode" />
                        </span>
                        <TextArea
                          name={"ldrRcraCode"}
                          clsName={"epa-code-ta"}
                          disabled={true}
                          noMaxLength={true}
                          placeholder={"Enter LDR RCRA Code"}
                          value={ldrRcraCode}
                          // value={values?.ldrRcraCode}
                          // onFieldChange={(e) => {
                          //   setFieldValue("ldrRcraCode", ldrRcraCode)
                          // }}
                        />
                      </div>
                      <div style={{ color: "red", fontSize: 10 }}>
                        {epaWasteCode?.length > 0 &&
                        (ldrRcraCode === null ||
                          ldrRcraCode === undefined ||
                          ldrRcraCode?.trim().length === 0) ? (
                          <div>LDR RCRA code is a required field</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-2">
                      {/* <div className="row m-0 float-right m-r-2r"> */}
                      <button
                        type="button"
                        className={`edit-button`}
                        onClick={() => setLDRRCRAEdit(true)}
                      >
                        Edit
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                    <div className="col-sm-8 ">
                      <div
                        className="form-input-block"
                        style={{ pointerEvents: "auto" }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.regulatoryinfo.ldruhc" />
                        </span>
                        <TextArea
                          name={"ldrUhcs"}
                          clsName={"epa-code-ta"}
                          noMaxLength={true}
                          placeholder={"Enter LDR UHC Code"}
                          disabled={true}
                          // value={noUhcFlag ? "" : ldrUhcs}
                          value={ldrUhcs}
                          // value={values?.ldrUhcs}
                          // onFieldChange={(e) => {
                          //   setFieldValue("ldrUhcs", ldrUhcs)
                          // }}
                        />
                      </div>
                      <div style={{ color: "red", fontSize: 10 }}>
                        {epaUhc?.length > 0 &&
                        (ldrUhcs === null ||
                          ldrUhcs === undefined ||
                          ldrUhcs?.trim().length === 0) ? (
                          <div>LDR UHC code is a required field</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-sm-2">
                      {/* <div className="row m-0 float-right m-r-2r"> */}
                      <button
                        type="button"
                        className={`edit-button`}
                        onClick={() => {
                          setLDRUHCEdit(true)
                        }}
                      >
                        Edit
                      </button>
                      {/* </div> */}
                      <div className="d-flex mt-2">
                        <label className="check-container">
                          <input
                            type="checkbox"
                            checked={values?.noUhcsFlag}
                            onChange={(e) => {
                              setFieldValue(
                                "noUhcsFlag",
                                e.target.checked ? true : false
                              )
                              // setNoUhcFlag(e.target.checked)
                              // if(e.target.checked){
                              //   try {
                              //     let ldrUhcReq = {
                              //       profileRequestId: currentProfile?.profileRequestId || 1,
                              //       ldrUhcDetailRequest: [],
                              //     }
                              //     let res = await api.p_saveLdrUhc(ldrUhcReq)
                              //     let payloadRes = await api.g_getSelectedLdrUhcs(
                              //       res?.data?.profileRequestId
                              //     )
                              //     dispatch({ type: SELECTED_LDR_UHCS, payload: payloadRes })
                              //     let payloadResAv = await api.g_getAvailableLdrUhcs(
                              //       res?.data?.profileRequestId
                              //     )
                              //     dispatch({ type: AVAILABLE_LDR_UHCS, payload: payloadResAv})
                              //   } catch (error) {}
                              // }
                            }}
                          />
                          <span className={"checkmark"} />
                        </label>
                        <span
                          className="pl-4 pr-0 text-description"
                          style={{ marginLeft: "16PX" }}
                        >
                          <FormattedMessage
                            id={"profile.regulatoryinfo.nouhc"}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                    <div className="col-sm-8 ">
                      <div
                        className="form-input-block mb-dropdown mni-style waste-water-dropdown-height"
                        style={{ marginLeft: 16, width: "100%" }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.regulatoryinfo.wastewater" />
                        </span>
                        <Select
                          name={"wasteWaterFlag"}
                          labelkey={"l"}
                          label=""
                          className={`sort-dropdown ${
                            values?.wasteWaterFlag == null
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={wasteWaterOpts.options}
                          selected={values?.wasteWaterFlag}
                          onSelect={(e) => {
                            setFieldValue("wasteWaterFlag", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="col-sm-8 ">
                      <div
                        className="form-input-block mb-dropdown mni-style ldrclass-dropdown-height"
                        style={{
                          zIndex: 1,
                          marginLeft: 16,
                          marginTop: 9,
                          width: "100%",
                        }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.regulatoryinfo.ldrclass" />
                        </span>
                        <Select
                          name={"ldrClassId"}
                          labelkey={"l"}
                          label=""
                          className={`sort-dropdown ${
                            values?.ldrClassId?.v == 0
                              ? "mni-selected-dropdown"
                              : ""
                          }`}
                          options={ldrClassOpts}
                          selected={values?.ldrClassId}
                          onSelect={(e) => {
                            setFieldValue("ldrClassId", e)
                          }}
                          disabled={type === "viewprofile"}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative"
                    style={{ justifyContent: "flex-start" }}
                  >
                    <div className="col-sm-4 mt-4">
                      <div
                        className="form-input-block"
                        style={{
                          zIndex: -1,
                        }}
                      >
                        <span className="label">
                          <FormattedMessage id="profile.regulatoryinfo.wadesignation" />
                        </span>
                        <Input
                          placeholder={"WA Designation"}
                          name={"waDesignation"}
                          disabled={true}
                          value={ldrRcraCode ? values?.waDesignation : ""}
                          onFieldChange={(e) => {
                            setFieldValue("waDesignation", e.target.value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
              <div className="mniChemDiv">
                <Modal show={wasteCodeEdit} wrapperclass={"edit-codes"}>
                  <div className="edit-modal">
                    <EditRegInfoCodes
                      availCodes={EpaWasteCodeOpts}
                      profileId={currentProfile?.profileRequestId}
                      type={"EPAWaste"}
                      searchHeader={"EPA Code"}
                      handleGetProfileData={handleGetProfileData}
                      selectedCodeOptions={selectedEpaWasteOpts || []}
                      setCode={handleSelectEditCode}
                      setFieldValue={setFieldValue}
                      name={"epaWasteCode"}
                      setCodeEdit={setWasteCodeEdit}
                    />
                  </div>
                </Modal>

                <Modal show={UHCEdit} wrapperclass={"edit-codes"}>
                  <div>
                    <EditRegInfoCodes
                      availCodes={EpaUhcOpts}
                      profileId={currentProfile?.profileRequestId}
                      selectedCodeOptions={selectedEpaUhcOpts || []}
                      type={"EPAUHC"}
                      searchHeader={"EPA UHC Code"}
                      handleGetProfileData={handleGetProfileData}
                      setCode={handleSelectEditCode}
                      setFieldValue={setFieldValue}
                      name={"epaUhc"}
                      setCodeEdit={setUHCEdit}
                    />
                  </div>
                </Modal>
                <Modal show={stateCodeEdit} wrapperclass={"edit-codes"}>
                  <div>
                    <EditRegInfoCodes
                      availCodes={EpaStateCodeOpts}
                      profileId={currentProfile?.profileRequestId}
                      selectedCodeOptions={selectedEpaStateCodeOpts || []}
                      type={"stateCode"}
                      searchHeader={"State Code"}
                      setCode={handleSelectEditCode}
                      handleGetProfileData={handleGetProfileData}
                      setFieldValue={setFieldValue}
                      // setEpaStateCodeCb={setEpaStateCodeCb}
                      name={"epaStateCode"}
                      setCodeEdit={setStateCodeEdit}
                    />
                  </div>
                </Modal>
                <Modal show={LDRRCRAEdit} wrapperclass={"edit-codes"}>
                  <div>
                    <EditRegInfoCodes
                      availCodes={
                        selectedEpaWasteOpts?.length > 0 ? ldrRcraOpts : []
                      }
                      profileId={currentProfile?.profileRequestId}
                      selectedCodeOptions={selectedLdrRcraOpts || []}
                      type={"LdrRCRA"}
                      searchHeader={"LDR RCRA Code"}
                      handleGetProfileData={handleGetProfileData}
                      setCode={handleSelectEditCode}
                      setFieldValue={setFieldValue}
                      name={"ldrRcraCode"}
                      setCodeEdit={setLDRRCRAEdit}
                    />
                  </div>
                </Modal>
                <Modal show={LDRUHCEdit} wrapperclass={"edit-codes"}>
                  <div>
                    <EditRegInfoCodes
                      availCodes={
                        selectedEpaUhcOpts?.length > 0 ? ldrUhcOpts : []
                      }
                      profileId={currentProfile?.profileRequestId}
                      selectedCodeOptions={selectedLdrUhcOpts || []}
                      type={"LdrUHC"}
                      searchHeader={"LDR UHC Code"}
                      setCode={handleSelectEditCode}
                      handleGetProfileData={handleGetProfileData}
                      noUhcFlag={values?.noUhcsFlag}
                      setFieldValue={setFieldValue}
                      name={"ldrUhcs"}
                      setCodeEdit={setLDRUHCEdit}
                    />
                  </div>
                </Modal>
              </div>
              {/* Questionnaire */}
              <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 generatorstatus addi-info text-left py-4">
                <div className="m-0 labelgenerator myprofile">
                  <FormattedMessage id="profile.neshaptitle" />
                </div>
              </div>
              <div>
                <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                  <div className="qstn-container" style={{ marginBottom: 12 }}>
                    <div className="text-heading">
                      <FormattedMessage
                        id={"profile.regulatoryinfo.benzenewasteqstn"}
                      />
                    </div>
                    <div className="px-2 dsp-flx py-1">
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="benzeneSectionFlag"
                          value={values?.benzeneSectionFlag}
                          checked={
                            values?.benzeneSectionFlag === true ? true : false
                          }
                          onChange={() => {
                            setFieldValue("benzeneSectionFlag", true)
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opyes"} />
                        </div>
                      </div>
                      <div className="ml-2 radio-container">
                        <input
                          id="radioCustomColor"
                          type="radio"
                          name="benzeneSectionFlag"
                          value={values?.benzeneSectionFlag}
                          checked={
                            values?.benzeneSectionFlag === false ? true : false
                          }
                          onChange={() => {
                            setFieldValue("benzeneWasteFlag", false)
                            setFieldValue("hazardousWasteNeshapFlag", false)
                            setFieldValue("naicsCodes", "")
                            setFieldValue("naicsCodes1Flag", false)
                            setFieldValue("naicsCodes2Flag", false)
                            setFieldValue("annualBenzene", "")
                            setFieldValue("yearTabCalculates", "")
                            setFieldValue("maxBenzene", "")
                            setFieldValue("maxBenzeneFlag", false)
                            setFieldValue("benzeneWater", false)
                            setFieldValue("benzeneSectionFlag", false)
                            setNaicsCode({
                              naics1: "",
                              naics2: "",
                              naics3: "",
                              naics4: "",
                            })
                          }}
                        />
                        <div className="px-4 text-description">
                          <FormattedMessage id={"profile.opno"} />
                        </div>
                      </div>
                    </div>
                  </div>
                  {values?.benzeneSectionFlag && (
                    <div className="qstn-container">
                      <div className="text-heading">
                        <FormattedMessage
                          id={"profile.regulatoryinfo.hazwasteqstn"}
                        />
                      </div>
                      <div className="px-2 dsp-flx py-1">
                        <div className="ml-2 radio-container">
                          <input
                            id="radioCustomColor"
                            type="radio"
                            name="hazardousWasteNeshapFlag"
                            value={values?.hazardousWasteNeshapFlag}
                            checked={
                              values?.hazardousWasteNeshapFlag === true
                                ? true
                                : false
                            }
                            onChange={() => {
                              setFieldValue("hazardousWasteNeshapFlag", true)
                              // setHazaradousWasteNeshapFlag(true)
                            }}
                          />
                          <div className="px-3 text-description">
                            <FormattedMessage id={"profile.opyes"} />
                          </div>
                        </div>
                        <div className="ml-2 radio-container">
                          <input
                            id="radioCustomColor"
                            type="radio"
                            name="hazardousWasteNeshapFlag"
                            value={values?.hazardousWasteNeshapFlag}
                            checked={
                              values?.hazardousWasteNeshapFlag === false
                                ? true
                                : false
                            }
                            onChange={() => {
                              setFieldValue("hazardousWasteNeshapFlag", false)
                              setFieldValue("naicsCodes", "")
                              setFieldValue("naicsCodes1Flag", false)
                              setFieldValue("naicsCodes2Flag", false)
                              setFieldValue("annualBenzene", "")
                              setFieldValue("yearTabCalculates", "")
                              setFieldValue("maxBenzene", "")
                              setFieldValue("maxBenzeneFlag", false)
                              setFieldValue("benzeneWater", false)
                              setFieldValue("benzeneSectionFlag", false)
                              setNaicsCode({
                                naics1: "",
                                naics2: "",
                                naics3: "",
                                naics4: "",
                              })
                              // setHazaradousWasteNeshapFlag(false)
                            }}
                          />
                          <div className="px-3 text-description">
                            <FormattedMessage id={"profile.opno"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {values?.benzeneSectionFlag && (
                <div>
                  <div
                    className="px-2 dsp-flx py-1 text-heading text-left"
                    style={{ marginLeft: 7 }}
                  >
                    <FormattedMessage id={"profile.regulatoryinfo.hazdesc"} />
                  </div>
                  {values?.hazardousWasteNeshapFlag && (
                    <div>
                      <div className="dsp-flx qstn col-sm-12 col-md-12 col-lg-12">
                        <div>
                          {
                            "If yes, Please note the appropirate NAICS code (s) if no, skip to # 6"
                          }
                        </div>
                        <div className="dsp-flx col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative qstn-input">
                          <div className="col-sm-4">
                            <div className="form-input-block">
                              <Input
                                placeholder={""}
                                name={"naics1"}
                                id={"naics1"}
                                value={naicsCode.naics1}
                                onFieldChange={(e) => {
                                  const { name, value } = e.target

                                  if (/^\d+$/.test(value)) {
                                    const updatedNaicsCode = {
                                      ...naicsCode,
                                      [name]: value.slice(0, 6),
                                    }

                                    setNaicsCode(updatedNaicsCode)
                                    setNaicsCombCode(
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                    setFieldValue(
                                      "naicsCodes",
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 ">
                            <div className="form-input-block">
                              <Input
                                placeholder={""}
                                name={"naics2"}
                                id={"naics2"}
                                value={naicsCode.naics2}
                                onFieldChange={(e) => {
                                  const { name, value } = e.target

                                  if (/^\d+$/.test(value)) {
                                    const updatedNaicsCode = {
                                      ...naicsCode,
                                      [name]: value.slice(0, 6),
                                    }

                                    setNaicsCode(updatedNaicsCode)
                                    setNaicsCombCode(
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                    setFieldValue(
                                      "naicsCodes",
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 ">
                            <div className="form-input-block">
                              <Input
                                placeholder={""}
                                name={"naics3"}
                                id={"naics3"}
                                value={naicsCode.naics3}
                                onFieldChange={(e) => {
                                  const { name, value } = e.target

                                  if (/^\d+$/.test(value)) {
                                    const updatedNaicsCode = {
                                      ...naicsCode,
                                      [name]: value.slice(0, 6),
                                    }

                                    setNaicsCode(updatedNaicsCode)
                                    setNaicsCombCode(
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                    setFieldValue(
                                      "naicsCodes",
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-4 ">
                            <div className="form-input-block">
                              <Input
                                placeholder={""}
                                name={"naics4"}
                                id={"naics4"}
                                value={naicsCode.naics4}
                                onFieldChange={(e) => {
                                  const { name, value } = e.target

                                  if (/^\d+$/.test(value)) {
                                    const updatedNaicsCode = {
                                      ...naicsCode,
                                      [name]: value.slice(0, 6),
                                    }

                                    setNaicsCode(updatedNaicsCode)
                                    setNaicsCombCode(
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                    setFieldValue(
                                      "naicsCodes",
                                      Object.values(updatedNaicsCode).join(",")
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-0 mb-4">
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                          <div className="qstn-container col-sm-12 col-md-12 col-lg-12">
                            <div
                              className="col-sm-10 col-md-10 col-lg-10"
                              style={{ marginLeft: -29 }}
                            >
                              {
                                "A. If Your NAICS Code is 331111, 331221, 324199, do you perform COKE oven by product recovery operations?"
                              }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 px-4 dsp-flx py-1">
                              <div className="ml-2 radio-container">
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="naicsCodes1Flag"
                                  value={values?.naicsCodes1Flag}
                                  checked={
                                    values?.naicsCodes1Flag === true
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setFieldValue("naicsCodes1Flag", true)
                                  }}
                                />
                                <div className="px-3 text-description">
                                  <FormattedMessage id={"profile.opyes"} />
                                </div>
                              </div>
                              <div className="ml-2 radio-container">
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="naicsCodes1Flag"
                                  value={values?.naicsCodes1Flag}
                                  checked={
                                    values?.naicsCodes1Flag === false
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setFieldValue("naicsCodes1Flag", false)
                                  }}
                                />
                                <div className="px-3 text-description">
                                  <FormattedMessage id={"profile.opno"} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                          <div className="qstn-container col-sm-12 col-md-12 col-lg-12">
                            <div
                              className="col-sm-10 col-md-10 col-lg-10"
                              style={{ marginLeft: -29 }}
                            >
                              {
                                "B. If Your NAICS Code is 562211, 562212, 562213, 562219, 562920, does your facility accept wastes from chemical manufacturing plants, coke by-product recovery plants or petroleum refineries?"
                              }
                            </div>
                            <div className="col-sm-2 col-md-2 col-lg-2 px-4 dsp-flx py-1">
                              <div className="ml-2 radio-container">
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="naicsCodes2Flag"
                                  value={values?.naicsCodes2Flag}
                                  checked={
                                    values?.naicsCodes2Flag === true
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setFieldValue("naicsCodes2Flag", true)
                                  }}
                                />
                                <div className="px-3 text-description">
                                  <FormattedMessage id={"profile.opyes"} />
                                </div>
                              </div>
                              <div className="ml-2 radio-container">
                                <input
                                  id="radioCustomColor"
                                  type="radio"
                                  name="naicsCodes2Flag"
                                  value={values?.naicsCodes2Flag}
                                  checked={
                                    values?.naicsCodes2Flag === false
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    setFieldValue("naicsCodes2Flag", false)
                                  }}
                                />
                                <div className="px-3 text-description">
                                  <FormattedMessage id={"profile.opno"} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-b-2 position-relative">
                    <div
                      className="col-sm-12"
                      // style={{ display: "flex", flexDirection: "row" }}
                    >
                      <div className="col-sm-8 bz-field">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.regulatoryinfo.tabqstn" />
                          </span>
                          <Input
                            placeholder={
                              "Enter Total Annual Benzene in Mega grams"
                            }
                            name={"annualBenzene"}
                            value={values?.annualBenzene}
                            type="number"
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue("annualBenzene", value)
                              }
                            }}
                            onBlurChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue(
                                  "annualBenzene",
                                  parseInt(value) || ""
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-4 bz-field">
                        <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.regulatoryinfo.yearqstn" />
                          </span>
                          <Input
                            name={"yearTabCalculates"}
                            type={"number"}
                            maxLen={4}
                            value={values?.yearTabCalculates}
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue("yearTabCalculates", value)
                              }
                            }}
                            onBlurChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue(
                                  "yearTabCalculates",
                                  parseInt(value) || ""
                                )
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="qstn-container">
                      <div className="text-heading" style={{ marginTop: -2 }}>
                        <FormattedMessage
                          id={"profile.regulatoryinfo.concqstn"}
                        />
                      </div>
                      <div
                        className="col-sm-2"
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <div className="px-2 dsp-flx py-1">
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="maxBenzeneFlag"
                              value={values?.maxBenzeneFlag}
                              checked={
                                values?.maxBenzeneFlag === true ? true : false
                              }
                              onChange={() => {
                                setFieldValue("maxBenzeneFlag", true)
                              }}
                            />
                            <div className="px-4 text-description">
                              <FormattedMessage id={"profile.opyes"} />
                            </div>
                          </div>
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="maxBenzeneFlag"
                              value={values?.maxBenzeneFlag}
                              checked={
                                values?.maxBenzeneFlag === false ? true : false
                              }
                              onChange={() => {
                                setFieldValue("maxBenzeneFlag", false)
                              }}
                            />
                            <div className="px-4 text-description">
                              <FormattedMessage id={"profile.opno"} />
                            </div>
                          </div>
                        </div>
                        {/* <div className="form-input-block">
                          <span className="label">
                            <FormattedMessage id="profile.regulatoryinfo.maxqstn" />
                          </span>
                          <Input
                            placeholder={"Enter Max Benzene PPM"}
                            name={"maxBenzene"}
                            value={values?.maxBenzene}
                            onFieldChange={(e) => {
                              setFieldValue(
                                "maxBenzene",
                                Number(e.target.value)
                              )
                            }}
                          />
                        </div>
                        <div style={{ color: "red", fontSize: 10 }}>
                          {errors.maxBenzene && touched.maxBenzene ? (
                            <div>Max Benzene value is required</div>
                          ) : null}
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div className="col-sm-8 bz-field">
                        <div
                          className="form-input-block"
                          style={{ marginTop: 20 }}
                        >
                          <span className="label">
                            <FormattedMessage id="profile.regulatoryinfo.maxqstn" />
                          </span>
                          <Input
                            placeholder={"Enter Max Benzene PPM"}
                            name={"maxBenzene"}
                            value={values?.maxBenzene}
                            type="number"
                            onFieldChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue("maxBenzene", value)
                              }
                            }}
                            onBlurChange={(e) => {
                              const { value } = e.target
                              if (/^\d*$/.test(value)) {
                                setFieldValue(
                                  "maxBenzene",
                                  parseInt(value) || ""
                                )
                              }
                            }}
                          />
                        </div>
                        <div
                          style={{
                            color: "red",
                            fontSize: 10,
                            position: "relative",
                            left: 14,
                            top: 4,
                          }}
                        >
                          {errors.maxBenzene && touched.maxBenzene ? (
                            <div>Max Benzene value is required</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="col-sm-12 col-md-12 col-lg-12 text-left py-2 m-0 position-relative">
                      <div className="qstn-container">
                        <div>
                          {"Does this waste contain greater than 10% water?"}
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 px-4 dsp-flx py-1">
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="benzeneWater"
                              value={values?.benzeneWater}
                              checked={
                                values?.benzeneWater === true ? true : false
                              }
                              onChange={() => {
                                setFieldValue("benzeneWater", true)
                              }}
                            />
                            <div className="px-3 text-description">
                              <FormattedMessage id={"profile.opyes"} />
                            </div>
                          </div>
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="benzeneWater"
                              value={values?.benzeneWater}
                              checked={
                                values?.benzeneWater === false ? true : false
                              }
                              onChange={() => {
                                setFieldValue("benzeneWater", false)
                              }}
                            />
                            <div className="px-3 text-description">
                              <FormattedMessage id={"profile.opno"} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="qstn-container" style={{ marginTop: 14 }}>
                        <div>
                          {
                            "Is this waste subject to the Benzene Waste Operations NESHAP controls (40 CFR Part 61 Subpart FF)?"
                          }
                        </div>
                        <div className="col-sm-2 col-md-2 col-lg-2 px-4 dsp-flx py-1">
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="benzeneWasteFlag"
                              value={values?.benzeneWasteFlag}
                              checked={
                                values?.benzeneWasteFlag === true ? true : false
                              }
                              onChange={() => {
                                setFieldValue("benzeneWasteFlag", true)
                              }}
                            />
                            <div className="px-3 text-description">
                              <FormattedMessage id={"profile.opyes"} />
                            </div>
                          </div>
                          <div className="ml-2 radio-container">
                            <input
                              id="radioCustomColor"
                              type="radio"
                              name="benzeneWasteFlag"
                              value={values?.benzeneWasteFlag}
                              checked={
                                values?.benzeneWasteFlag === false
                                  ? true
                                  : false
                              }
                              onChange={() => {
                                setFieldValue("benzeneWasteFlag", false)
                              }}
                            />
                            <div className="px-3 text-description">
                              <FormattedMessage id={"profile.opno"} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*Back, Save and Cancel actions*/}
              {type !== "viewprofile" && (
                <div className="row m-0 new-row-block">
                  <div className="col-sm-10 col-md-12 col-lg-12 text-left pr-0 mt-2 ml-4">
                    <div className="row m-0 float-left">
                      <button
                        type="button"
                        className={`cancel-button`}
                        onClick={() => backAction()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="submit"
                        ref={ref}
                        className={`green-button`}
                        // onClick={() => submit()}
                      >
                        Save & Continue
                      </button>
                    </div>
                    <div className="row m-0 float-right m-r-2r">
                      <button
                        type="button"
                        className="cancel-button"
                        onClick={() => cancelAction()}
                      >
                        Cancel
                      </button>
                    </div>
                    <span className="float-right text-red m-r-2r">
                      {`*Required Fields`}
                    </span>
                  </div>
                  <AlertModal
                    showError={showError}
                    setShowError={setShowError}
                    seekingStep={seekingStep}
                    submitAction={() =>
                      handleAlerts(values, errors, isValid, seekingStep)
                    }
                    showAlert={showAlert}
                    setShowAlert={setShowAlert}
                  />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  )
}

export default statereginfoenhancer(StateRegulatoryInfo)
